import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import moment from "moment";
import Loading from "./Loading";
import { toast } from "react-toastify";
import { logSuccess } from "../rtsvrf-actions/Logging";
import { updateArtistSubscription } from "../rtsvrf-actions/Actions";

function Subscription({
  artistEditor,
  artist,
  reloadData,
  user,
  userIpData,
  artistSubscription,
  loading,
}) {
  const [subscription, setSubscription] = useState();
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    setSubscription(artistSubscription);
  }, [artistSubscription]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);
    ReactGA.event({
      category: "Save Artist Subscription Role",
      action: "click",
      label: "Save Artist Subscription Role",
    });
    if (
      artistSubscription?.artist_billing_role ===
      subscription?.artist_billing_role
    ) {
      toast.warn("Select different value for role.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const profileResult = await updateArtistSubscription(
      artist?.rtsvrf_artist_id,
      {
        artist_billing_role: subscription?.artist_billing_role,
        artist_billing_role_relationship_timestamp: new Date(),
      }
    );

    if (profileResult?.error) {
      toast.error("Failed to update profile! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await logSuccess(
        `${artist?.artist_name || "Artist"} Subscription Role Updated`,
        {
          ...userIpData,
          email: user.email,
          timestamp: new Date(),
          details: `${artist?.artist_name || "Artist"}:${
            artist?.rtsvrf_artist_id
          } Role: ${subscription?.artist_billing_role}`,
          type: artist?.rtsvrf_artist_id,
        }
      );
      reloadData();
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {subscription ? (
        <div className="my-10 sm:w-full min-2xl:ml-28">
          <h3 className="text-center text-lg my-2 font-bold text-white">
            Artist AV Subscription
          </h3>
          <form class="w-full min-w-full max-w-xl bg-gray-200 shadow-xl rounded-lg p-4">
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Subscription Role
                </label>
                <div class="relative">
                  <select
                    onChange={(e) =>
                      setSubscription({
                        ...subscription,
                        artist_billing_role: e.target.value,
                      })
                    }
                    defaultValue={subscription.artist_billing_role ?? ""}
                    class="block appearance-none text-xs w-full bg-white border border-gray-200 text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value={"not set"}>Not set</option>
                    <option value={"self"}>Self</option>
                    <option value={"delegate"}>Delegate</option>
                    <option value={"sponsor"}>Sponsor</option>
                  </select>
                </div>
              </div>
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Subscription Role Timestamp
                </label>
                <input
                  defaultValue={
                    subscription.artist_billing_role_relationship_timestamp
                      ? moment(
                          subscription.artist_billing_role_relationship_timestamp
                        ).format("DD MMMM YYYY")
                      : ""
                  }
                  disabled={true}
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Subscription Status Date"
                />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Transaction ID
                </label>
                <input
                  defaultValue={
                    subscription.artist_subscription_vendor_txn ?? "N/A"
                  }
                  disabled={true}
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Transaction ID"
                />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Subscription Target
                </label>
                <input
                  defaultValue={
                    subscription.artist_billing_role_relationship_target
                  }
                  disabled={true}
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Subscription Target"
                />
              </div>
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Subscription Target Timestamp
                </label>
                <input
                  defaultValue={
                    subscription.artist_billing_role_relationship_target_timestamp
                      ? moment(
                          subscription.artist_billing_role_relationship_target_timestamp
                        ).format("DD MMMM YYYY")
                      : ""
                  }
                  disabled={true}
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Subscription Status Date"
                />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Subscription Status
                </label>
                <input
                  defaultValue={subscription.artist_subscription_status}
                  disabled={true}
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Subscription Status"
                />
              </div>
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Subscription Status Date
                </label>
                <input
                  defaultValue={
                    subscription.artist_subscription_status_timestamp
                      ? moment(
                          subscription.artist_subscription_status_timestamp
                        ).format("DD MMMM YYYY")
                      : ""
                  }
                  disabled={true}
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Subscription Status Date"
                />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Subscription Start Date
                </label>
                <input
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  disabled={true}
                  defaultValue={
                    subscription.subscription_start_date_timestamp
                      ? moment(
                          subscription.subscription_start_date_timestamp
                        ).format("DD MMMM YYYY")
                      : ""
                  }
                  placeholder="Date"
                />
              </div>
              <div class="w-1/2 md:w-full px-3 mb-6 ">
                <label class="block uppercase tracking-wide w-60 text-gray-500 text-xs font-bold mb-2">
                  Subscription End Date
                </label>
                <input
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  disabled={true}
                  defaultValue={
                    subscription.subscription_end_date_timestamp
                      ? moment(
                          subscription.subscription_end_date_timestamp
                        ).format("DD MMMM YYYY")
                      : ""
                  }
                  placeholder="Date"
                />
              </div>
            </div>
          </form>
          <div className="flex justify-center">
            <button
              type="button"
              onClick={(e) => handleSubmit(e)}
              className="w-40 my-2 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
            >
              {submitting ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      ) : (
        <div className="my-10 sm:w-full flex items-center justify-center min-2xl:ml-28">
          <h3 className="text-center text-lg my-2 font-bold text-white">
            No subscription found for the user
          </h3>
        </div>
      )}
    </>
  );
}

export default Subscription;
