import React, { useState, useEffect } from "react";

import {
  getArtistDataByRtsvrfId,
  getArtistEditableFields,
  getArtistEditorMappingsById,
  getArtistImage,
  getArtistPreferences,
  getArtistProfile2Preferences,
  getArtistSettings,
  getArtistSubscription,
  getLogHistory,
  getTotalLogHistory,
  getUserAuth,
} from "../rtsvrf-actions/Actions";
import Pagination from "../rtsvrf-components/Pagination";
import Subscription from "../rtsvrf-components/Subscription";
import Loading from "../rtsvrf-components/Loading";
import Navbar from "../rtsvrf-components/Navbar";
import { useParams, useSearchParams } from "react-router-dom";
import ArtistProfile from "../rtsvrf-components/ArtistProfile";
import EditArtistProfile from "../rtsvrf-components/EditArtistProfile";
import Table from "../rtsvrf-components/Table";
import { Tab, Tabs } from "../rtsvrf-components/Tabs";

import ArtistNewsSettings from "../rtsvrf-components/ArtistNewsSettings";
import EditArtistProfileV2 from "../rtsvrf-components/EditArtistProfileV2";
import EditMasterProfile from "../rtsvrf-components/EditMasterProfile";

function ArtistEdit({ userIpData }) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [artistEditor, setArtistEditor] = useState();
  const [artistPreferences, setArtistPreferences] = useState();
  const [artistSettings, setArtistSettings] = useState();
  const [artistSubscription, setArtistSubscription] = useState();
  const [artistProfile2Preferences, setArtistProfile2Preferences] = useState();
  const [editableFieldsStatus, setEditableFieldsStatus] = useState();
  const [delegatedArtist, setDelegatedArtist] = useState(null);

  const [artistImage, setArtistImage] = useState([]);
  const [logs, setLogs] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const listPerPage = 5;
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") || "master"
  );
  const { userId, artistId } = useParams();

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, artistId]);

  const reloadData = async () => {
    if (loading) return;
    setLoading(true);
    const userAuthData = await getUserAuth();
    setUser(userAuthData);

    const result = await getArtistEditorMappingsById(userId);
    const userData = result.data;
    console.log(userData, "userData");
    if (userData) {
      if (
        userData?.editor_delegated_artists &&
        userData.editor_delegated_artists.find((artist) => artist === artistId)
      ) {
        setArtistEditor(userData);
        const artist = await getArtistDataByRtsvrfId(artistId);
        setDelegatedArtist(artist);
        const result = await getArtistSettings(artist.rtsvrf_artist_id);
        if (result) {
          setArtistSettings(result);
        } else {
          setArtistSettings({
            rtsvrf_artist_id: artist.rtsvrf_artist_id,
            web_search: true,
            twitter: true,
            instagram: false,
            facebook: false,
            related_artists: false,
            genre_news: false,
            industry_news: false,
          });
        }
        const subscription = await getArtistSubscription(
          artist.rtsvrf_artist_id
        );
        if (subscription) {
          setArtistSubscription(subscription);
        }
        const ProfileV2preferences = await getArtistProfile2Preferences(
          artist.rtsvrf_artist_id
        );
        if (ProfileV2preferences) {
          setArtistProfile2Preferences(ProfileV2preferences);
        } else {
          setArtistProfile2Preferences({
            rtsvrf_artist_id: artist.rtsvrf_artist_id,
            artist_image: true,
            link_icons_list: [],
            artist_news: true,
            social_stream_embed1: null,
            social_stream_embed2: null,
            merch_offer: null,
            tickets_offer: null,
          });
        }
        const images = await getArtistImage([artist?.rtsvrf_artist_id]);
        setArtistImage(
          images && images[0]
            ? images[0][images[0].artist_picture_preference]
            : null
        );
      } else {
        setDelegatedArtist(null);
        setArtistImage(null);
      }
    }
    const list = await getArtistPreferences(artistId);
    setArtistPreferences(list || { rtsvrf_artist_id: artistId });

    const fields = await getArtistEditableFields(artistId);
    setEditableFieldsStatus(
      fields || {
        rtsvrf_artist_id: artistId,
        website_link_status: true,
        facebk_link_status: true,
        twitter_link_status: true,
        instag_link_status: true,
        discrd_link_status: true,
        tiktok_link_status: true,
        spotify_link_status: true,
        aplmus_link_status: true,
        deezer_link_status: true,
        youtube_link_status: true,
        amazon_link_status: true,
        twitch_link_status: true,
        vimeo_link_status: true,
        tidal_link_status: true,
        sound_link_status: true,
        sound_cloud_link_status: true,
        audius_link_status: true,
        threads_link_status: true,
        snapchat_link_status: true,
        discord_link_status: true,
        mastodon_link_status: true,
        bluesky_link_status: true,
        signal_link_status: true,
        whatsapp_link_status: true,
        telegram_link_status: true,
        published: true,
        tickets_platform_link_status: true,
        merchandise_platform_link_status: true,
        shows_platform_link_status: true,
        community_platform_link_status: true,
        artist_news_link_status: true,
      }
    );
    if (userAuthData) {
      const logsHistory = await getLogHistory(userAuthData.email, artistId);
      if (logsHistory?.data) setLogs(logsHistory.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    async function fetchPaginationData() {
      if (!user) return;
      const numberOfRecords = await getTotalLogHistory(user.email, artistId);
      setTotal(numberOfRecords);
      const logsHistory = await getLogHistory(
        user.email,
        artistId,
        currentPage,
        listPerPage
      );
      if (logsHistory?.data) setLogs(logsHistory.data);
    }
    fetchPaginationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className={
        "bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 "
      }
    >
      {user && (
        <Navbar
          user={user}
          userIpData={userIpData}
          links={[
            { label: "Home", link: `/artadm/${user?.id}` },
            { label: "Artist Admin", link: null },
            { label: delegatedArtist?.artist_name || "Artist", link: null },
          ]}
        />
      )}
      <Tabs>
        <Tab
          component={
            <>
              <div className="flex lg:flex-col justify-around lg:items-center">
                {user && (
                  <ArtistProfile
                    artistImage={artistImage}
                    userIpData={userIpData}
                    allowEdit={true}
                    user={user}
                    reloadData={reloadData}
                    artist={delegatedArtist}
                  />
                )}

                {user ? (
                  <EditMasterProfile
                    artistEditor={artistEditor}
                    reloadData={reloadData}
                    artistPreferences={artistPreferences}
                    editableFieldsStatus={editableFieldsStatus}
                    artist={delegatedArtist}
                    user={user}
                    userIpData={userIpData}
                  />
                ) : null}
              </div>
            </>
          }
          currentTab={"master"}
          activeTab={activeTab}
          active={!activeTab || activeTab === "master"}
          handleTab={() => {
            setActiveTab("master");
          }}
        >
          Master Control
        </Tab>
        <Tab
          component={
            <>
              <div className="flex lg:flex-col justify-around lg:items-center">
                {user && (
                  <ArtistProfile
                    artistImage={artistImage}
                    userIpData={userIpData}
                    allowEdit={true}
                    user={user}
                    reloadData={reloadData}
                    artist={delegatedArtist}
                  />
                )}

                {user ? (
                  <EditArtistProfile
                    artistEditor={artistEditor}
                    reloadData={reloadData}
                    artistPreferences={artistPreferences}
                    editableFieldsStatus={editableFieldsStatus}
                    artist={delegatedArtist}
                    user={user}
                    userIpData={userIpData}
                  />
                ) : null}
              </div>
              <div>
                <Table feed={logs} />
                {logs?.length ? (
                  <div className="py-2 flex items-center justify-center">
                    <Pagination
                      listPerPage={listPerPage}
                      total={total}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                ) : null}
              </div>
            </>
          }
          currentTab={"v1"}
          activeTab={activeTab}
          active={activeTab === "v1"}
          handleTab={() => {
            setActiveTab("v1");
          }}
        >
          Metadata and Links
        </Tab>
        <Tab
          currentTab={activeTab}
          activeTab={activeTab}
          active={false}
          disable={true}
          handleTab={() => {
            setActiveTab(activeTab);
            setTimeout(() => {
              console.log(activeTab);
              setActiveTab(activeTab);
              window.open(
                `${process.env.REACT_APP_PROFILE_URL}/artist/${artistId}`,
                "_blank",
                "rel=noopener noreferrer"
              );
              setActiveTab(activeTab);
            }, 200);
            setActiveTab(activeTab);
          }}
        >
          V1 Profile View
        </Tab>
        <Tab
          component={
            <>
              <div className="flex lg:flex-col justify-around lg:items-center">
                {user && (
                  <ArtistProfile
                    artistImage={artistImage}
                    userIpData={userIpData}
                    allowEdit={true}
                    user={user}
                    reloadData={reloadData}
                    artist={delegatedArtist}
                  />
                )}

                {user ? (
                  <EditArtistProfileV2
                    artistEditor={artistEditor}
                    reloadData={reloadData}
                    artist={delegatedArtist}
                    user={user}
                    artistProfile2Preferences={artistProfile2Preferences}
                    userIpData={userIpData}
                  />
                ) : null}
              </div>
            </>
          }
          currentTab={"v2"}
          activeTab={activeTab}
          active={!activeTab || activeTab === "v2"}
          handleTab={() => {
            setActiveTab("v2");
          }}
        >
          V2 CMS
        </Tab>
        <Tab
          currentTab={activeTab}
          activeTab={activeTab}
          active={false}
          disable={true}
          handleTab={() => {
            console.log(activeTab, "active");
            setActiveTab(activeTab);
            setTimeout(() => {
              window.open(
                `${process.env.REACT_APP_PROFILE_URL}/artist/${artistId}?layout=v2`,
                "_blank",
                "rel=noopener noreferrer"
              );
            }, 200);
          }}
        >
          V2 Profile View
        </Tab>

        <Tab
          hide={!artistEditor?.artist_av_subs_mgr}
          component={
            <>
              <div className="flex lg:flex-col justify-around lg:items-center">
                {user && (
                  <ArtistProfile
                    artistImage={artistImage}
                    userIpData={userIpData}
                    allowEdit={true}
                    user={user}
                    reloadData={reloadData}
                    artist={delegatedArtist}
                  />
                )}

                {user ? (
                  <Subscription
                    artistEditor={artistEditor}
                    reloadData={reloadData}
                    loading={loading}
                    artist={delegatedArtist}
                    user={user}
                    artistSubscription={artistSubscription}
                    userIpData={userIpData}
                  />
                ) : null}
              </div>
            </>
          }
          currentTab={"subscription"}
          activeTab={activeTab}
          active={!activeTab || activeTab === "subscription"}
          handleTab={() => {
            setActiveTab("subscription");
          }}
        >
          Artist AV Subscription
        </Tab>

        <Tab
          component={
            <>
              <div className="flex lg:flex-col justify-around lg:items-center">
                {user && (
                  <ArtistProfile
                    artistImage={artistImage}
                    userIpData={userIpData}
                    allowEdit={true}
                    user={user}
                    reloadData={reloadData}
                    artist={delegatedArtist}
                  />
                )}

                {user ? (
                  <ArtistNewsSettings
                    artistEditor={artistEditor}
                    reloadData={reloadData}
                    artistPreferences={artistPreferences}
                    editableFieldsStatus={editableFieldsStatus}
                    artist={delegatedArtist}
                    user={user}
                    artistSettings={artistSettings}
                    userIpData={userIpData}
                  />
                ) : null}
              </div>
            </>
          }
          currentTab={"news"}
          activeTab={activeTab}
          active={!activeTab || activeTab === "news"}
          handleTab={() => {
            setActiveTab("news");
          }}
        >
          Artist News
        </Tab>
      </Tabs>
    </div>
  );
}

export default ArtistEdit;
